export enum WorkflowStatus {
    Draft = 'draft',
    Ready = 'ready',
    Updating = 'updating',
    Deprecated = 'deprecated',
    Suspended = 'suspended',
}

export enum WorkflowExecutionStatus {
    Draft = 'draft',
    Queued = 'queued',
    Running = 'running',
    Failed = 'failed',
    Cancelled = 'cancelled',
    Completed = 'completed',
    Ready = 'ready',
    Deprecated = 'deprecated',
    Updating = 'updating',
    Suspended = 'suspended',
}
